import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent, EmailLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'login/:path',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page',
    },
  },
  {
    path: 'apps/email',
    component: EmailLayoutComponent,

    children: [
      {
        path: '',
        loadChildren: () =>
          import('./views/apps/email/email.module').then((m) => m.EmailModule),
      },
    ],
  },

  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      // {
      //   path: 'admins',
      //   loadChildren: () =>
      //     import('./views/admins/admins.module').then((m) => m.AdminsModule)
      // },
      {
        path: 'banners',
        loadChildren: () =>
          import('./views/banners/banners.module').then((m) => m.BannersModule)
      },
      {
        path: 'manufacturers',
        loadChildren: () =>
            import('./views/manufacturers/manufacturers.module').then((m) => m.ManufacturersModule)
      },
      {
        path: 'featured-categories',
        loadChildren: () =>
          import('./views/featured-categories/featured-categories.module').then((m) => m.FeaturedCategoriesModule)
      },
      {
        path: 'e-brochures',
        loadChildren: () =>
          import('./views/e-brochures/e-brochures.module').then((m) => m.BrochuresModule)
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./views/customers/customers.module').then((m) => m.CustomersModule)
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./views/products/products.module').then((m) => m.ProductsModule)
      },
      {
        path: 'quote-requests',
        loadChildren: () =>
            import('./views/quoteRequests/quoteRequests.module').then((m) => m.QuoteRequestsModule)
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./views/contactUs/contactUs.module').then((m) => m.ContactUsModule)
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./views/orders/orders.module').then((m) => m.OrdersModule)
      },
      {
        path: 'credit-notes',
        loadChildren: () =>
          import('./views/creditNotes/creditNotes.module').then((m) => m.CreditNotesModule)
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule),
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule),
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/buttons.module').then((m) => m.ButtonsModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/chartjs/chartjs.module').then((m) => m.ChartjsModule),
      },
      {
        path: 'editors',
        loadChildren: () =>
          import('./views/editors/editors.module').then((m) => m.EditorsModule),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./views/forms/forms.module').then((m) => m.FormsModule),
      },
      {
        path: 'google-maps',
        loadChildren: () =>
          import('./views/maps/maps.module').then((m) => m.MapsModule),
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then((m) => m.IconsModule),
      },
      {
        path: 'plugins',
        loadChildren: () =>
          import('./views/plugins/plugins.module').then((m) => m.PluginsModule),
      },
      {
        path: 'tables',
        loadChildren: () =>
          import('./views/tables/tables.module').then((m) => m.TablesModule),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('./views/apps/apps.module').then((m) => m.AppsModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
            import('./views/settings/taxes/taxes.module').then((m) => m.TaxesModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
            import('./views/settings/attributes/attributes.module').then((m) => m.AttributesModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
            import('./views/settings/engineered-solutions/engineered-solutions.module').then((m) => m.EngineeredSolutionsModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
            import('./views/settings/rebuild-elastic/rebuild-elastic.module').then((m) => m.RebuildElasticModule),
      },
      {
        path: 'vacancies',
        loadChildren: () =>
          import('./views/vacancies/vacancies.module').then((m) => m.VacanciesModule),
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./views/news-categories/news-categories.module').then((m) => m.NewsCategoriesModule),
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./views/news-articles/news-articles.module').then((m) => m.NewsArticlesModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
