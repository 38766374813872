import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(public util: UtilService) { }

  public redirectURL = '';

  isLoggedIn(){
    var result = false;

    // NB maybe needed later if this cookie is needed by GC. It was there for MG2 previously
    if(this.util.getCookie('customer')){
      result = true;
    }

    if(this.util.getCookie('admin_token')){
      result = true;
    }

    return result;
  }

  checkRole() {
    return this.util.getCookie('admin_role');
  }

  public setRedirectURL(url: string) {
    this.redirectURL = url;
  }
}
