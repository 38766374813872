import { Component, HostBinding, OnInit } from '@angular/core';
import { LoaderService } from '../../global/loader.service';
import { SessionService } from '../../global/session.service';

import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  styleUrls: ['./default-layout.component.scss'],
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {
  @HostBinding('class.c-app') cAppClass = true;

  public navItems = navItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  public showLoader = null;
  public showMessage = null;

  userRole: string = null;

  constructor(private loaderService: LoaderService, public session: SessionService) {
    console.warn(this.navItems);
    this.userRole = this.session.checkRole();
    // Only Grab Data if user role has access
    // Role = 1 is admin, show all
    // Role = 2 is warehouse, only show orders
    // Role = 3 is finance, only show customers

    if (this.userRole == '2' ) {
      this.navItems = this.navItems.filter(( nav => nav.name != 'Customers'));
      this.navItems = this.navItems.filter(( nav => nav.name != 'Admins'));
    }

    if (this.userRole == '3') {
      this.navItems = this.navItems.filter(( nav => nav.name != 'Orders'));
      this.navItems = this.navItems.filter(( nav => nav.name != 'Credit Notes'));
      this.navItems = this.navItems.filter(( nav => nav.name != 'Admins'));
    }

    if (this.userRole === '8') {
      console.log({ items: this.navItems });
      
      this.navItems = this.navItems.reduce((acc, nav) => {
        if (nav.name === "Log Out") {
          acc.push(nav);
        }

        return acc
      }, []);
    }
  }

  ngOnInit(): void {
    this.loaderService.showLoader.subscribe(showLoader => {
      this.showLoader = showLoader;
    });


    this.loaderService.showMessage.subscribe(showMessage => {
      this.showMessage = showMessage;
    });
  }
}
